//Color Define
$separator: rgba(84, 84, 88, 0.6);
$opaqueSeparator: rgba(56, 56, 58, 1);
$placeholderText: rgba(235, 235, 245, 0.3);

$background0: rgba(0, 0, 0, 1);
$background1: rgba(28, 28, 30, 1);
$background2: rgba(44, 44, 46, 1);

$fill0: rgba(120, 120, 128, 0.36);
$fill1: rgba(120, 120, 128, 0.32);
$fill2: rgba(118, 118, 128, 0.24);
$fill3: rgba(118, 118, 128, 0.18);

$label0: rgba(255, 255, 255, 1);
$label1: rgba(235, 235, 245, 0.6);
$label2: rgba(235, 235, 245, 0.3);
$label3: rgba(235, 235, 245, 0.18);

$gray0: rgba(142, 142, 147, 1);
$gray1: rgba(174, 174, 178, 1);
$gray2: rgba(199, 199, 204, 1);
$gray3: rgba(209, 209, 214, 1);
$gray4: rgba(229, 229, 234, 1);
$gray5: rgba(242, 242, 247, 1);

// $green: #32d74b;
// rgba(109, 218, 155, 1.000)
$green: rgba(87, 218, 142, 0.86);
$blue: #0a84ff;
$red: #d96057;

@import url("https://fonts.googleapis.com/css2?family=Oxanium:wght@400;500&display=swap");
.is-number {
  font-family: Oxanium;
}

// body
body {
  background: #000;
  font-family: system-ui, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;
}

// media
.media {
  display: flex;
  margin-bottom: 0.5rem;
  background: #78788020;
  padding: 0.125rem;
  .title {
    font-family: "Oxanium";
    // margin-top: 0.125rem;
    // margin-bottom: -0.125rem;
  }
}

.media.dropdown {
  background: #0000;
  margin: 0;
  padding: 0;

  .media-left {
    margin-right: 0.5rem;
    margin-top: 0.25rem;
  }
}

.media.dropdown .media-content {
  .title {
    font-size: 0.875rem;
    // line-height: 1.1;
  }
  .subtitle {
    font-size: 0.75rem;
    // line-height: 1;
  }
}

.media-left {
  margin-right: 1rem;
  margin-top: 0.125rem;
}

.image.is-32x32 {
  height: 32px;
  width: 32px;
}

.image.is-24x24 {
  height: 24px;
  width: 24px;
}

.image img {
  display: block;
  height: auto;
  width: 100%;
}
.image {
  display: block;
  position: relative;
}

.media img {
  border: 0.0625rem solid #54545899;
}

figure {
  margin: 0;
  padding: 0;
}

.title > a.is-label {
  // color: $gray4;
  &:empty:after {
    content: "[New Record]";
    // padding-left: 2rem;
    color: $gray1;
  }
}

.media-content {
  flex-basis: auto;
  -webkit-box-flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  text-align: inherit;
}
.media a {
  cursor: pointer;
  text-decoration: none;
}

.non-sold-switch {
  margin-bottom: 1rem;
}

.media-price {
  line-height: 40px;
  color: #fff;
  margin-right: 0.125rem;
}

// .media-price:before {
//   content: " ";
//   padding-left: 1.375rem;
//   background: url(https://cros-wow.c70.ca/images/icons/money-gold.gif) center
//     no-repeat;
// }

// .media-price:empty:before {
//   background: url(https://cros-wow.c70.ca/images/wow/icons/medium/inv_enchant_disenchant.jpg)
//     center no-repeat;
//   background-size: 24px 24px;
//   padding-left: 24px;
//   content: "";
//   font-size: 24px;
// }

.media-price:empty {
  height: 24px;
}

.media-content > .title:empty:after {
  content: "  ";
  padding-right: 1rem;
}

.media-content > .subtitle:empty:after {
  content: "  ";
  padding-right: 1rem;
}

.title {
  line-height: 1.25;
  color: #f5f5f7;
  &.is-half-cut {
    padding-left: 0.5rem;
    &:after {
      content: " ½";
    }
  }
}

.subtitle {
  font-size: 0.875rem;
  line-height: 1.25;
  color: #b1b1b9;
}

p {
  margin: 0;
  padding: 0;
}

.item-select-containter {
  width: 400px;
  z-index: 10;
  position: absolute !important;
  top: 3.125rem;
}

.item-select__indicators .item-select__indicator-separator {
  display: none;
}

.item-select__menu-list {
  scroll-snap-type: y mandatory;
  background: $background1;
  padding-top: 0 !important;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ebebf54d;
  }
  &::-webkit-scrollbar-track {
    // background: transparent;
    background: $background2;
  }
}

.item-select__plain {
  padding: 8px 12px;
}

.item-select__option {
  border-bottom: 1px solid $separator;
  padding: 0 !important;
  // background: $fill0;
  padding: 0.25rem !important;
  padding-top: 0.125rem !important;
}

.item-select__option--is-focused {
  background-color: #ebebf52e !important;
}

.item-select__option--is-selected {
  background-color: $background2 !important;
  border-bottom: 2px solid #fff;
}

.item-select__control {
  background-color: $background1 !important;
  border-color: $separator !important;
}

.item-select__input-container {
  color: $gray2 !important;
}

.item-select__indicator {
  color: $gray1 !important;
  &:hover {
    color: $gray0 !important;
  }
}

// raid
main.raid-record-main {
  background: #111;
  // width: 680px;
  padding: 0.375rem 0.5rem;
  /* display: flex; */
  height: 460px;
  overflow: auto;
  grid-area: record-main;
  // margin-bottom: 0.375rem;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ebebf54d;
  }
  &::-webkit-scrollbar-track {
    // background: transparent;
    background: $background2;
  }

  .raid-record-empty {
    font-size: 1.5rem;
    height: 5rem;
    margin: auto;
    color: $gray0;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    font-family: "Oxanium";
    font-variant: small-caps;
  }

  .raid-record-header {
    background: $background2;
    padding: 0.375rem 0.625rem;
    color: $gray3;
    margin: -0.375rem -0.5rem;
    // margin: 0 -0.5rem;
    margin-bottom: 0.375rem;
    position: sticky;
    z-index: 1;
    top: -0.375rem;
    font-family: "Oxanium";
    //fix fonts
    padding-top: 0.5rem;
    // padding-bottom: 0.25rem;
  }
}

main.raid-record-extra {
  @extend .raid-record-main;
  height: 180px;
  grid-area: record-extra;

  .raid-record-row .subtitle {
    font-family: "Oxanium";
    &::after {
      // margin-bottom: -0.25rem;
      padding-top: 0.125rem;
      font-variant: petite-caps;
    }
    &.is-credit::after {
      content: "Credit";
      color: $green;
    }
    &.is-expense::after {
      content: "Expense";
      color: $red;
    }
  }
}

.record-edit-menu {
  display: flex;
  height: 100%;
  z-index: 21;
  background: $background1;
  font-family: Oxanium;
  position: absolute;
  top: 0;
  left: 3rem;

  .control .input {
    text-align: left;
    padding-left: 0.5rem;
    padding-top: 0.25rem;
    font-weight: unset;
  }

  .type-select {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 0.125rem 0;
    align-items: flex-end;
    .box {
      // width: 3.75rem;
      font-size: 0.75rem;
      transition: all 250ms ease;
      will-change: transition;
      // display: flex;

      span {
        // background: $fill1;
        color: $gray1;
        padding: 0.075rem 0.25rem;
        padding-bottom: 0;
        border: 0.1rem solid $separator;
        border-radius: 4px;
        // margin-bottom: -0.25rem;
      }
    }
  }

  input[type="radio"] {
    display: none;
    &:checked {
      + .box {
        // background-color: $green;
        span {
          color: adjust-color($background2, $alpha: 0.8);
          background: $green;
        }
      }
    }
  }
}

.raid-record-row {
  background: #78788020;
  margin-bottom: 0.375rem;
  display: flex;
  // justify-content: space-between;

  .item-wrapper {
    position: relative;
  }

  .item-wrapper > .media {
    // margin-bottom: 0.375rem;
    margin-bottom: 0;
    width: 400px;
    background: none;
  }
}

.raid-record-row .price-wrapper {
  justify-content: flex-end;
  margin-left: auto;
  padding: 0.25rem;
  position: relative;

  > .x-icon {
    margin: 0;
    height: 100%;
  }

  .price-context {
    min-width: 4rem;
    display: flex;
    align-content: center;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    cursor: pointer;
    padding: 0 0.5rem;
    margin-right: -0.5rem;
    font-family: Oxanium;
    font-variant-numeric: tabular-nums;

    &:hover {
      background: $fill1;
      border-radius: 4px;
    }
    .x-icon {
      margin: 0;
    }
    .price-text {
      color: $gray4;
      font-size: 1.125rem;
      font-weight: 500;
      text-align: right;
      padding-left: 1rem;
      margin-bottom: -0.25rem;
    }
  }

  .price-dropdown {
    background: #1e1e1e;
    height: 100%;
    position: absolute;
    top: 0;
    width: 7rem;
    z-index: 21;
    right: 0;
    font-family: Oxanium;
    font-variant-numeric: tabular-nums;
    .input {
      padding-top: 0.25rem;
    }
  }
}

.raid-record-row .dropdown-menu {
  float: right;
  padding: 0.25rem 0;
  position: relative;
  justify-content: flex-end;

  > .button {
    opacity: 0;
    height: 100%;
    padding: 0;

    &:hover {
      background: none;
      opacity: 1;
    }
  }

  .button.show {
    opacity: 1;
  }

  .menu-context {
    position: absolute;
    height: 100%;
    display: flex;
    // background: $fill2;
    background: #333337;
    top: 0;
    right: 1.125rem;
    align-items: center;
    padding: 0 0.125rem;
    // opacity: 1;
    .button {
      margin: 0 0.25rem;
      padding: 0;
      width: 2rem;
      > span.icon {
        display: flex;
        justify-content: center;
      }
    }
  }
}

.raid-containter {
  display: grid;
  grid-gap: 0.375rem;
  grid-template-columns: 680px 260px;
  grid-template-areas:
    "control summary"
    "record-main summary"
    "record-extra summary";
  width: calc(680px + 260px + 0.375rem);
  margin: auto;
  margin-top: 2rem;
  padding: 0.375rem;
  outline: $fill1 solid 0.25rem;

  &.is-loading {
    > * {
      visibility: hidden;
    }
  }

  .raid-message {
    position: absolute;
    z-index: 2;
    visibility: visible;
    color: $label0;
    width: 30rem;
    height: 10rem;
    margin: auto;
    background: #111;
    left: 50%;
    margin-top: 8rem;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Oxanium";
    font-size: 1.25rem;
    font-weight: 500;
    border-radius: 4px;
  }

  .raid-message .message:before {
    content: "";
    width: 1.75rem;
    height: 1.75rem;
    border: 0.25rem solid $gray3;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    position: absolute;
    margin-left: -2.25rem;
    margin-top: -0.5rem;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .raid-message.is-not-found {
    flex-direction: column;
    height: 14rem;
    i.icon {
      height: 2.5rem;
      width: 2.5rem;
      > svg {
        fill: $red;
      }
    }
    .error-message {
      margin-top: 1.25rem;
      margin-bottom: 1.75rem;
    }
    .button {
      font-size: 1rem;
    }
  }
}

// aside raid-summary
.raid-summary {
  grid-area: summary;
  background: #111;
}

// control
.raid-control {
  grid-area: control;
  // width: 680px;
  padding: 0.25rem 0.5rem;
  background: #111;
  // margin-bottom: 0.375rem;
  display: flex;
  flex-direction: row-reverse;

  .button {
    background: #0000;
    border: none;
    margin-left: 0.25rem;
  }

  .raid-title {
    order: 100;
    flex-grow: 1;
    display: flex;
    align-content: center;
    align-items: center;
    .title {
      font-family: Oxanium;
      font-weight: 500;
      margin-left: 0.25rem;
      margin-bottom: -0.125rem;
      color: $gray4;
      max-width: 16rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    span.icon.pad {
      padding: 1px 6px;
    }
    i.icon > svg {
      fill: $gray3;
    }
    .button {
      margin-left: 0;
    }
  }
}

// button

.button {
  user-select: none;
  height: 2rem;
  cursor: pointer;
  border-radius: 4px;
  border: none;

  &.is-text {
    border: 0.1rem solid $separator;
    height: unset;
    padding: calc(0.5rem - 1px) 2rem;
    //
    font-weight: 500;
    color: $label1;
    background: rgba(235, 235, 245, 0.12);
    &:hover {
      background: rgba(235, 235, 245, 0.24);
    }
    &.fix {
      padding-bottom: calc(0.5rem - 1px - 0.125rem);
    }
    &.is-primary {
      color: rgba(28, 28, 30, 0.76);
      background: $gray1;
      &:hover {
        background: $gray2;
      }
    }
  }

  &.no-bg {
    background: none;
    border: none;
  }

  &:hover {
    background: $fill1;
  }

  i.icon {
    width: 1.125rem;
    height: 1.125rem;

    > svg {
      display: block;
      fill: $gray1;
      width: inherit;
      height: inherit;
    }
  }
}

.control {
  display: flex;
  height: 100%;
  &.has-icon-right .input {
    padding-right: 2.5rem;
  }

  .input,
  .select {
    width: 100%;
    margin: 0.25rem;
    text-align: right;
    background: $fill0;
    outline: none;
    border: 1px solid $separator;
    border-radius: 4px;
    color: $gray4;
    font-weight: 500;
    &:focus {
      border: 0.1rem solid $blue;
    }
  }

  .select {
    font-weight: 400;
    margin-right: 1rem;
    color: $gray3;
    option:disabled {
      color: $gray2;
    }
    // optgroup, option {
    //   font-size: 0.875rem;
    //   font-family: Oxanium;
    // }
  }

  .checkbox {
    display: none;
    &:checked + label {
      border-color: $gray3;
      background: $fill0;
      &:after {
        background: $gray5;
        margin-left: 2rem;
      }
    }
    & + label {
      background: none;
      border: 0.125rem solid $label2;
      // padding-left: 4rem;
      border-radius: 4px;
      margin-right: 0.25rem;
      width: 3.5rem;
      position: relative;
      &:after {
        transition: all 0.25s ease-out;
        content: " ";
        border-radius: 4px;
        background: $gray1;
        height: 1.25rem;
        width: 1.25rem;
        display: block;
        margin-top: 0.25rem;
        margin-left: 0.25rem;
      }
    }
  }

  span.icon {
    user-select: none;
    // height: 2rem;
    height: 100%;
    cursor: pointer;
    border-radius: 4px;
    display: inline-flex;
    z-index: 25;
    align-items: center;
    position: absolute;

    &.is-right {
      right: 0.75rem;
      justify-content: flex-end;
    }
  }
}

i.icon {
  width: 1.125rem;
  height: 1.125rem;

  > svg {
    display: block;
    fill: $gray1;
    width: inherit;
    height: inherit;
  }

  &.is-hover {
    padding: 0.25rem;
    &:hover {
      background: $fill1;
      border-radius: 4px;
    }
  }
}

i.is-green > svg {
  fill: $green;
}

.x-icon {
  display: block;
  height: 1.5rem;
  width: 1.5rem;
  margin: auto;
  background-size: contain !important;
}

.x-icon-disenchant {
  // background: url("https://cros-wow.c70.ca/images/wow/icons/medium/inv_enchant_disenchant.jpg")
  background: url("https://cdn-z3.c70.ca/icons/inv_enchant_disenchant.webp")
    no-repeat center;
}

.x-icon-extra {
  // background: url("https://cros-wow.c70.ca/images/wow/icons/medium/inv_enchant_disenchant.jpg")
  background: url("https://cdn-z3.c70.ca/icons/wow_token01.webp")
    no-repeat center;
}

.x-icon-gold {
  // background: url("https://cdn.jsdelivr.net/gh/tywtyw2002/public_cdn@wow/icons/inv_misc_coin_01.png")
  background: url("https://cdn-z3.c70.ca/icons/inv_misc_coin_01.webp") no-repeat
    center;
}

.x-icon-gold2 {
  // background: url("https://cdn.jsdelivr.net/gh/tywtyw2002/public_cdn@wow/icons/inv_misc_coin_02.png")
  background: url("https://cdn-z3.c70.ca/icons/inv_misc_coin_02.webp") no-repeat
    center;
}

.x-icon-gold3 {
  // background: url("https://cdn.jsdelivr.net/gh/tywtyw2002/public_cdn@wow/icons/inv_misc_coin_17.png")
  background: url("https://cdn-z3.c70.ca/icons/inv_misc_coin_17.webp") no-repeat
    center;
}

// modal
.modal-background {
  background: #000000a8;
  inset: 0;
  position: absolute;
  z-index: 10;
}

.modal {
  z-index: 100;
  width: 600px;
  height: 400px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background: #111;

  .modal-head {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    background-color: $background1;
    display: flex;
    flex-shrink: 0;
    flex-grow: 0;
    justify-content: flex-start;
    padding: 0.875rem 1.5rem;
    // padding-right: 0.875rem;
    position: relative;
    align-items: center;

    .title {
      color: $label1;
      font-size: 1.375rem;
      line-height: 1.5;
      font-family: Oxanium;
      font-weight: 500;
      margin-bottom: -0.25rem;
      &:empty:before {
        content: " ";
        padding-left: 1rem;
      }
      flex-grow: 1;
    }

    span.icon {
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-right: -0.5rem;
      &:hover {
        background: $fill1;
        border-radius: 4px;
      }
    }
  }

  .modal-body {
    flex-grow: 1;
    padding: 0.875rem 1.5rem;
    flex-direction: column;
    display: flex;
    // flex-shrink: 1;
    .dynamic-size {
      flex-grow: 1;
    }
  }

  .modal-foot {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: $background1;
    display: flex;
    flex-shrink: 0;
    flex-grow: 0;
    justify-content: flex-start;
    padding: 0.875rem 1.5rem;
    position: relative;
    justify-content: flex-end;

    .foot-buttons {
      .button {
        margin-left: 1rem;
      }
    }
  }
}

.textarea {
  border: 0.1rem solid $separator;
  // width: 100%;
  padding: calc(0.5rem - 1px) calc(0.75rem - 1px);
  resize: none;
  background: $background1;
  border-radius: 4px;
  outline: none;
  color: $label1;
  margin-bottom: 0.75rem;
  &:focus-visible {
    border-color: $blue;
    padding: calc(0.625rem - 1px) calc(0.875rem - 1px);
  }
  &::placeholder {
    color: $placeholderText;
  }
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ebebf54d;
  }
  &::-webkit-scrollbar-track {
    // background: transparent;
    background: $background2;
  }
  &.is-fixwidth {
    font-family: "PragmataPro Liga", ui-monospace, SFMono-Regular, SF Mono,
      Menlo, Consolas, Liberation Mono, monospace;
    font-size: 0.875rem;
  }
}

.field {
  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }
}

.switch[type="checkbox"] {
  outline: 0;
  user-select: none;
  display: inline-block;
  position: absolute;
  opacity: 0;
  & + label {
    color: $label1;
    position: relative;
    font-size: 1rem;
    padding-left: 3rem;
    padding-top: 0.125rem;
    cursor: pointer;
    &::before {
      background: #0000;
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 2.25rem;
      height: 1.125rem;
      border: 0.1rem solid $separator;
      border-radius: 4px;
      content: " ";
    }
    &::after {
      display: block;
      position: absolute;
      top: 0.25rem;
      left: 0.25rem;
      width: 0.825rem;
      height: 0.825rem;
      transform: translate3d(0, 0, 0);
      border-radius: 4px;
      transition: all 0.25s ease-out;
      content: " ";
      background: $separator;
    }
  }
  &:checked + label {
    &::before {
      border-color: $gray1;
    }
    &::after {
      left: 1.375rem;
      background: $gray1;
    }
  }
}

.raid-card-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.375rem;
  padding: 0.375rem;
  grid-template-rows: repeat(auto-fill, 3rem);

  .card {
    display: flex;
    background: $background2;
    padding: 0 0.875rem;
    align-items: center;

    .title {
      font-size: 1.25rem;
      margin: 0.5rem;
      margin-right: 0;
      margin-bottom: 0.375rem;
      font-family: "Oxanium";
      font-variant-numeric: tabular-nums;
      text-align: right;
      flex-grow: 1;
      color: $gray3;

      &.is-gold {
        color: #e6cc80;
      }
    }

    i.icon {
      width: 1.375rem;
      height: 1.375rem;
    }

    &.is-double {
      grid-row: 2 span;
      flex-direction: column;
      align-items: center;

      i.icon {
        width: 2.25rem;
        height: 2.25rem;
      }

      span.icon {
        margin-top: 1rem;
      }

      .title {
        margin: 0.5rem;
        color: $gray4;
      }
    }

    &.gold-card {
      display: flex;
      padding: 1rem 1.625rem;
      justify-content: space-between;
      background: none;
      grid-row: 2 span;
      grid-column: 2 span;

      .group-right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      .title {
        color: #f5f5f7;
        font-size: 2rem;
        margin: 0;
        margin-bottom: -0.5rem;
      }

      .subtitle {
        font-family: "Oxanium";
        text-transform: uppercase;
      }

      .group-left {
        display: flex;
        align-items: center;
        .x-icon {
          width: 2.5rem;
          height: 2.5rem;
        }
      }
    }

    &.is-record {
      grid-column: 2 span;
      height: 2.5rem;

      .title {
        order: 3;
        flex-grow: 0;
      }

      .subtitle {
        flex-grow: 1;
        font-family: Oxanium;
        padding-left: 0.75rem;
        padding-top: 0.5rem;
        height: 100%;
        &[data-desc]::before {
          content: attr(data-desc);
          margin-top: 1.125rem;
          position: absolute;
          font-size: 0.75rem;
          color: $label2;
        }
        // &[data-count="1"]::before {
        //   content: unset;
        // }
      }
    }
    &.copiable {
      cursor: pointer;
    }
  }

  .card.is-copied {
    position: relative;
    &:after {
      content: "Copied";
      font-size: 0.875rem;
      font-family: "Oxanium";
      z-index: 20;
      position: absolute;
      background: $background1;
      border-radius: 4px;
      padding: 0.5rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding-left: 2rem;
      font-weight: 500;
      color: $green;
    }
    &:before {
      content: " ";
      -webkit-mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z'/%3E%3C/svg%3E");
      width: 1rem;
      height: 1rem;
      background-color: $green;
      z-index: 21;
      -webkit-mask-repeat: no-repeat;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      margin-left: -1.5rem;
    }
  }
}

.raid-config-bonus {
  font-family: "Oxanium";
  margin-top: 0.375rem;

  .config-header {
    display: flex;
    align-items: flex-end;
    .title {
      color: $label1;
      margin-left: 0.5rem;
      flex-grow: 1;
    }
    .button {
      background: #0000;
      border: none;
      margin-left: 0.5rem;
      height: 1.5rem;
      &:hover {
        background: $fill1;
      }
    }
  }
  .config-containter {
    margin-top: 0.375rem;
    border-radius: 4px;
    background: $background1;
    height: 200px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #ebebf54d;
    }
    &::-webkit-scrollbar-track {
      // background: transparent;
      background: $background2;
    }
  }
  .raid-config-row {
    height: 1.875rem;
  }
}

.raid-config-row {
  display: flex;
  align-items: center;
  font-family: "Oxanium";
  border-radius: 4px;
  background: $fill1;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0.375rem;
  position: relative;
  // padding-right: 0.375rem;

  span.icon {
    margin-right: 1rem;
    i.icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  p.title {
    // color: $label1;
    // font-size: 1rem;
    font-weight: 400;
    min-width: 10rem;
    margin-bottom: -0.125rem;
    // height: 100%;
  }

  .control {
    flex-grow: 1;
    justify-content: flex-end;
    &.with-radio {
      position: relative;
      .input {
        font-weight: 400;
        margin: 0;
        width: 3.5rem;
        text-align: center;
        padding-top: 0.25rem;
        margin-right: 0.75rem;
      }
      // &::after {
      //   font-size: 1rem;
      //   color: $gray2;
      //   top: calc(50% + 0.125rem);
      //   position: absolute;
      //   transform: translate(-50%, -50%);
      // }
      // &.is-fixed::after {
      //   content: "G";
      //   right: -0.375rem;
      // }
      // &.is-percent::after {
      //   content: "%";
      //   right: -0.625rem;
      // }
    }
  }

  &.simple-input {
    height: 2rem;
    .input {
      margin: 0;
      width: 4rem;
      text-align: center;
      padding-top: 0.25rem;
      margin-right: 0.75rem;
    }
    .select {
      margin: 0;
      text-align: center;
      padding-top: 0.25rem;
      margin-right: 0.125rem;
      padding-left: 0.75rem;
    }
  }

  .select-overlayer {
    display: flex;
    position: absolute;
    // height: 100%;
    // padding: 0.375rem;
    z-index: 10;
    background: rgba(59, 59, 62, 1);

    .control {
      height: unset;
      margin: 0 1rem;
      input.input {
        text-align: left;
        margin: 0;
        font-weight: 400;
        padding-top: 0.25rem;
        padding-left: 0.5rem;
        width: 12rem;
      }
      &.has-icon-right {
        .input {
          padding-right: 2rem;
        }
        span.icon {
          right: 0.25rem;
        }
        i.icon {
          width: 1.25rem;
          height: 1.25rem;
          &.is-hover {
            padding: 0.125rem;
          }
        }
      }
    }

    .icon-selector {
      display: flex;

      input[type="radio"] {
        display: none;
        &:checked + .box-icon {
          background: $fill0;
          border-color: $gray5;
          svg {
            fill: $label0;
          }
        }
      }

      .box-icon {
        cursor: pointer;
        border-radius: 4px;
        border: 0.1rem solid $label2;
        padding: 0.325rem;
        margin-right: 0.325rem;
      }
    }
  }

  .control.with-radio .type-select {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    // margin: 0.125rem 0;
    // align-items: flex-end;
    align-items: flex-start;
    margin-top: -0.125rem;
    margin-bottom: -0.25rem;
    margin-right: 0.5rem;
    order: 2;
    .box {
      font-size: 0.75rem;
      transition: all 250ms ease;
      will-change: transition;

      span {
        color: $gray1;
        padding: 0.075rem 0.25rem;
        padding-bottom: 0;
        border: 0.1rem solid $label2;
        border-radius: 4px;
      }
    }

    input[type="radio"] {
      display: none;
      &:checked + .box {
        span {
          background: $fill0;
          border-color: $gray5;
          color: $label0;
        }
      }
    }
  }
}

.home-containter {
  width: calc(680px + 260px + 0.375rem);
  height: 728px;
  margin: auto;
  margin-top: 2rem;
  padding: 0.375rem;
  outline: $fill1 solid 0.25rem;
  display: grid;
  grid-gap: 0.375rem;
  grid-template-columns: 680px 260px;
}

.home-main {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .record-control {
    font-family: Oxanium;
  }
}
.record-containter {
  display: flex;
  flex-direction: column;
  font-family: Oxanium;
  margin-top: 0.25rem;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ebebf54d;
  }
  &::-webkit-scrollbar-track {
    // background: transparent;
    background: $background2;
  }

  section.group {
    flex-grow: 1;
    display: grid;
    grid-gap: 0.375rem;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1.25rem repeat(auto-fill, 5rem);
    //grid-template-rows: 1.25rem repeat(auto-fill, 5rem);
    grid-auto-rows: 5rem;

    margin: 0.375rem 0.5rem;

    header {
      color: $gray3;
      grid-column: 3 span;
      margin-left: 0.75rem;
    }

    .card {
      border-radius: 4px;
      background: $background1;
      display: flex;

      .layer {
        position: relative;
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        // backdrop-filter: blur(1px);
        padding: 0.375rem 0.5rem;
        flex-grow: 1;
        background: rgba(11, 11, 11, 0.32);
        cursor: pointer;

        .title {
          background: change-color($background1, $alpha: 0.6);
          border-radius: 4px;
          color: $gray2;
          max-width: 12.5rem;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          margin-bottom: 0.125rem;
          padding: 0.125rem 0.25rem;
          padding-bottom: 0;
          width: fit-content;
        }
        .subtitle {
          background: change-color($background2, $alpha: 0.6);
          color: $gray1;
          width: fit-content;
          border-radius: 4px;
          padding: 0.125rem 0.25rem;
          padding-bottom: 0;
        }
        .button {
          // display: none;
          background: none;
        }

        &:hover {
          // background: rgba(11, 11, 11, 0.12);
          background: none;
          outline: 1px solid $gray1;
          .btn-remove {
            display: block;
            height: 1.875rem;
            &:hover {
              background: change-color($fill1, $alpha: 0.8);
            }
          }
        }
        .btn-remove {
          display: none;
          position: absolute;
          right: 0.125rem;
          top: 0.125rem;
          z-index: 1;
          i.icon > svg {
            fill: $gray3;
          }
        }
      }

      &.is-template {
        &:hover {
          .btn-add {
            opacity: 1;
          }
          .title, .subtitle {
            opacity: 0;
          }
        }
        .btn-add {
          opacity: 0;
          background: change-color($background1, $alpha: 0.6);
          position: absolute;
          top: 1rem;
          left: calc(50% - 22px);
          // left: calc(50% - 1.25rem);
          z-index: 1;
          // width: 2.5rem;
          height: 2.75rem;
          i.icon {
            width: 2rem;
            height: 2rem;
            svg {
              fill: $gray2;
            }
          }
        }

      }

      &.is-tbc-bt {
        // background: url("https://cdn.jsdelivr.net/gh/tywtyw2002/public_cdn@wow/slash/tbc_bt.png");
        background: url("https://cdn-z3.c70.ca/slash/tbc_bt.webp");
        background-position: -20px -15px;
      }
      &.is-tbc-swp {
        // background: url("https://cdn.jsdelivr.net/gh/tywtyw2002/public_cdn@wow/slash/tbc_swp.png");
        background: url("https://cdn-z3.c70.ca/slash/tbc_swp.webp");
        background-position: -20px -5px;
      }
      &.is-wotlk-naxx {
        // background: url("https://cdn.jsdelivr.net/gh/tywtyw2002/public_cdn@wow/slash/wotlk_naxx.png");
        background: url("https://cdn-z3.c70.ca/slash/wotlk_naxx.webp");
        background-position: -40px -100px;
      }
      &.is-wotlk-eoe {
        // background: url("https://cdn.jsdelivr.net/gh/tywtyw2002/public_cdn@wow/slash/wotlk_eoe.png");
        background: url("https://cdn-z3.c70.ca/slash/wotlk_eoe.webp");
        background-position: -60px -80px;
      }
      &.is-wotlk-ob {
        // background: url("https://cdn.jsdelivr.net/gh/tywtyw2002/public_cdn@wow/slash/wotlk_ob.png");
        background: url("https://cdn-z3.c70.ca/slash/wotlk_ob.webp");
        background-position: -40px -150px;
      }
      &.is-wotlk-toc {
        // background: url("https://cdn.jsdelivr.net/gh/tywtyw2002/public_cdn@wow/slash/wotlk_ob.png");
        background: url("https://cdn-z3.c70.ca/slash/wotlk_toc.webp");
        background-position: -15px -75px;
        background-size: 250px;
      }
      &.is-wotlk-icc {
        // background: url("https://cdn.jsdelivr.net/gh/tywtyw2002/public_cdn@wow/slash/wotlk_ob.png");
        background: url("https://cdn-z3.c70.ca/slash/wotlk_icc.webp");
        background-size: 260px;
        background-position: -25px 0px;
      }
      &.is-wotlk-uld {
        // background: url("https://cdn.jsdelivr.net/gh/tywtyw2002/public_cdn@wow/slash/wotlk_ob.png");
        background: url("https://cdn-z3.c70.ca/slash/wotlk_uld.webp");
        background-position: -15px -10px;
      }
      &.is-wotlk-rs {
        // background: url("https://cdn.jsdelivr.net/gh/tywtyw2002/public_cdn@wow/slash/wotlk_ob.png");
        background: url("https://cdn-z3.c70.ca/slash/wotlk_rs.webp");
        background-position: -10px -80px;
      }
      &.is-wotlk-ony {
        // background: url("https://cdn.jsdelivr.net/gh/tywtyw2002/public_cdn@wow/slash/wotlk_ob.png");
        background: url("https://cdn-z3.c70.ca/slash/wotlk_ony.webp");
        background-position: -10px 0px;
        background-size: 260px;
      }
      &.is-wotlk-vault {
        // background: url("https://cdn.jsdelivr.net/gh/tywtyw2002/public_cdn@wow/slash/wotlk_ob.png");
        background: url("https://cdn-z3.c70.ca/slash/wotlk_vault.webp");
        background-position: 0px -30px;
        background-size: 250px;
      }
    }
  }
}

.home-info-screen {
  display: flex;
  align-items: center;
  margin-top: 6rem;
  flex-direction: column;
  font-family: Oxanium;

  &.loading {
    .title {
      font-weight: 500;
      font-size: 1.5rem;
      margin-top: 1.5rem;
    }
  }

  &.ops {
    i.icon {
      width: 4rem;
      height: 4rem;
    }
    span.icon {
      margin-bottom: 1rem;
    }
    .title {
      font-size: 1.25rem;
      margin-bottom: 1rem;
    }
  }

  .is-loading {
    width: 6rem;
    height: 6rem;
    border: 0.5rem solid $gray3;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    // animation: rotation 1s linear infinite;

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.raid-export-loot {
  width: 669px;
  margin-left: auto;
  // overflow-x: auto;

  overflow-y: scroll;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  position: relative;
  &::-webkit-scrollbar {
    display: none;
  }
  .loot-control-bar {
    // height: 32px;
    display: flex;
    flex-direction: row-reverse;
    z-index: 10;
    position: absolute;
    right: 0;
    margin-top: 0.5rem;
    margin-right: 0.125rem;
    .x-icon {
      margin-right: 0.5rem;
      // margin-left: 0.5rem;
      cursor: pointer;
      filter: grayscale(100%);
      &:hover {
        filter: grayscale(40%);
      }
      &.is-select {
        filter: none;
        // &:hover {
        //   filter: grayscale(50%);
        // }
      }
    }
  }

  .loot-body {
    // &::-webkit-scrollbar {
    //   display: none;
    // }
    // overflow-x: hidden;
    // overflow-y: auto;
    background: #111;
    header {
      .layer {
        background: #0b0b0b52;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
      }
      height: 150px;
      .raid-title {
        display: flex;
        padding-left: 0.75rem;
        padding-top: 1rem;
        flex-direction: column;
        .title {
          font-family: Oxanium;
          color: #c7c7cc;
          font-size: 1.5rem;
          background: #1c1c1e99;
          width: fit-content;
          padding: 0.25rem 0.375rem 0;
          border-radius: 4px;
          margin-bottom: 0.125rem;
        }
        .subtitle {
          font-size: 1.25rem;
          background: #2c2c2e99;
          width: fit-content;
          padding: 0.25rem 0.375rem;
          border-radius: 4px;
          color: #aeaeb2;
        }
      }
      .raid-info {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-end;
        padding-right: 0.75rem;
        padding-bottom: 1rem;
        .subtitle {
          font-size: 1rem;
          background: #2c2c2e99;
          width: fit-content;
          padding: 0.125rem 0.25rem;
          margin-top: 0.125rem;
          border-radius: 4px;
          color: #aeaeb2;
          &:empty {
            display: none;
          }
        }
      }
    }
    main {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
    .raid-record-row {
      height: 41.5px;
      .price-wrapper {
        margin-right: 1rem;
      }
      .item-wrapper {
        margin-left: 0.625rem;
      }
      &.x-disenchant > .price-wrapper {
        .price-context {
          justify-content: flex-end;
          // margin-right: 0.25rem;
        }
        .price-text {
          display: none;
        }
        .x-icon-gold {
          background: url("https://cdn-z3.c70.ca/icons/inv_enchant_disenchant.webp")
    no-repeat center;
        }
      }
      &.x-extra.x-credit .subtitle{
        color: #57da8edb;
        font-variant: petite-caps;
      }
      &.x-extra .subtitle{
        color: #d96057;
        font-variant: petite-caps;
      }
      // &.x-extra .media {
      //   height: 41.5px;
      // }
      &.x-extra .title > a:empty:after {
        content: "N/A";
      }
      .title {
        display: inline-block;
      }
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.Toastify__toast-body {
  white-space: pre-line;
}
