@import "https://fonts.googleapis.com/css2?family=Oxanium:wght@400;500&display=swap";
:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: #ffffffb3;
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient( to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55 );
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index) px);
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
  padding: 4px;
  position: fixed;
}

.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}

.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}

.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}

.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    margin: 0;
    padding: 0;
    left: 0;
  }

  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}

.Toastify__toast {
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  max-height: var(--toastify-toast-max-height);
  font-family: var(--toastify-font-family);
  cursor: pointer;
  direction: ltr;
  z-index: 0;
  border-radius: 4px;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 8px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 10px #0000001a, 0 2px 15px #0000000d;
}

.Toastify__toast--rtl {
  direction: rtl;
}

.Toastify__toast-body {
  flex: auto;
  align-items: center;
  margin: auto 0;
  padding: 6px;
  display: flex;
}

.Toastify__toast-body > div:last-child {
  flex: 1;
}

.Toastify__toast-icon {
  -webkit-margin-end: 10px;
  width: 20px;
  flex-shrink: 0;
  margin-inline-end: 10px;
  display: flex;
}

.Toastify--animate {
  animation-duration: .7s;
  animation-fill-mode: both;
}

.Toastify--animate-icon {
  animation-duration: .3s;
  animation-fill-mode: both;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    border-radius: 0;
    margin-bottom: 0;
  }
}

.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}

.Toastify__toast-theme--light, .Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}

.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}

.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}

.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}

.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}

.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  cursor: pointer;
  opacity: .7;
  background: none;
  border: none;
  outline: none;
  align-self: flex-start;
  padding: 0;
  transition: all .3s;
}

.Toastify__close-button--light {
  color: #000;
  opacity: .3;
}

.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}

.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  width: 100%;
  height: 5px;
  z-index: var(--toastify-z-index);
  opacity: .7;
  transform-origin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}

.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear forwards;
}

.Toastify__progress-bar--controlled {
  transition: transform .2s;
}

.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: 100%;
}

.Toastify__spinner {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 2px solid;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  border-radius: 100%;
  animation: Toastify__spin .65s linear infinite;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    visibility: visible;
    transform: translate3d(110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}

@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.is-number {
  font-family: Oxanium;
}

body {
  background: #000;
  font-family: system-ui, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.media {
  background: #78788020;
  margin-bottom: .5rem;
  padding: .125rem;
  display: flex;
}

.media .title {
  font-family: Oxanium;
}

.media.dropdown {
  background: none;
  margin: 0;
  padding: 0;
}

.media.dropdown .media-left {
  margin-top: .25rem;
  margin-right: .5rem;
}

.media.dropdown .media-content .title {
  font-size: .875rem;
}

.media.dropdown .media-content .subtitle {
  font-size: .75rem;
}

.media-left {
  margin-top: .125rem;
  margin-right: 1rem;
}

.image.is-32x32 {
  height: 32px;
  width: 32px;
}

.image.is-24x24 {
  height: 24px;
  width: 24px;
}

.image img {
  height: auto;
  width: 100%;
  display: block;
}

.image {
  display: block;
  position: relative;
}

.media img {
  border: .0625rem solid #54545899;
}

figure {
  margin: 0;
  padding: 0;
}

.title > a.is-label:empty:after {
  content: "[New Record]";
  color: #aeaeb2;
}

.media-content {
  text-align: inherit;
  flex: auto;
}

.media a {
  cursor: pointer;
  text-decoration: none;
}

.non-sold-switch {
  margin-bottom: 1rem;
}

.media-price {
  color: #fff;
  margin-right: .125rem;
  line-height: 40px;
}

.media-price:empty {
  height: 24px;
}

.media-content > .title:empty:after, .media-content > .subtitle:empty:after {
  content: "  ";
  padding-right: 1rem;
}

.title {
  color: #f5f5f7;
  line-height: 1.25;
}

.title.is-half-cut {
  padding-left: .5rem;
}

.title.is-half-cut:after {
  content: " ½";
}

.subtitle {
  color: #b1b1b9;
  font-size: .875rem;
  line-height: 1.25;
}

p {
  margin: 0;
  padding: 0;
}

.item-select-containter {
  width: 400px;
  z-index: 10;
  top: 3.125rem;
  position: absolute !important;
}

.item-select__indicators .item-select__indicator-separator {
  display: none;
}

.item-select__menu-list {
  scroll-snap-type: y mandatory;
  background: #1c1c1e;
  padding-top: 0 !important;
}

.item-select__menu-list::-webkit-scrollbar {
  width: 8px;
}

.item-select__menu-list::-webkit-scrollbar-thumb {
  background-color: #ebebf54d;
}

.item-select__menu-list::-webkit-scrollbar-track {
  background: #2c2c2e;
}

.item-select__plain {
  padding: 8px 12px;
}

.item-select__option {
  border-bottom: 1px solid #54545899;
  padding: .125rem .25rem .25rem !important;
}

.item-select__option--is-focused {
  background-color: #ebebf52e !important;
}

.item-select__option--is-selected {
  border-bottom: 2px solid #fff;
  background-color: #2c2c2e !important;
}

.item-select__control {
  background-color: #1c1c1e !important;
  border-color: #54545899 !important;
}

.item-select__input-container {
  color: #c7c7cc !important;
}

.item-select__indicator {
  color: #aeaeb2 !important;
}

.item-select__indicator:hover {
  color: #8e8e93 !important;
}

main.raid-record-main, main.raid-record-extra {
  height: 460px;
  background: #111;
  grid-area: record-main;
  padding: .375rem .5rem;
  overflow: auto;
}

main.raid-record-main::-webkit-scrollbar, main.raid-record-extra::-webkit-scrollbar {
  width: 8px;
}

main.raid-record-main::-webkit-scrollbar-thumb, main.raid-record-extra::-webkit-scrollbar-thumb {
  background-color: #ebebf54d;
}

main.raid-record-main::-webkit-scrollbar-track, main.raid-record-extra::-webkit-scrollbar-track {
  background: #2c2c2e;
}

main.raid-record-main .raid-record-empty, main.raid-record-extra .raid-record-empty {
  height: 5rem;
  color: #8e8e93;
  font-variant: small-caps;
  place-content: center;
  align-items: center;
  margin: auto;
  font-family: Oxanium;
  font-size: 1.5rem;
  display: flex;
}

main.raid-record-main .raid-record-header, main.raid-record-extra .raid-record-header {
  color: #d1d1d6;
  z-index: 1;
  background: #2c2c2e;
  margin: -.375rem -.5rem .375rem;
  padding: .5rem .625rem .375rem;
  font-family: Oxanium;
  position: sticky;
  top: -.375rem;
}

main.raid-record-extra {
  height: 180px;
  grid-area: record-extra;
}

main.raid-record-extra .raid-record-row .subtitle {
  font-family: Oxanium;
}

main.raid-record-extra .raid-record-row .subtitle:after {
  font-variant: petite-caps;
  padding-top: .125rem;
}

main.raid-record-extra .raid-record-row .subtitle.is-credit:after {
  content: "Credit";
  color: #57da8edb;
}

main.raid-record-extra .raid-record-row .subtitle.is-expense:after {
  content: "Expense";
  color: #d96057;
}

.record-edit-menu {
  height: 100%;
  z-index: 21;
  background: #1c1c1e;
  font-family: Oxanium;
  display: flex;
  position: absolute;
  top: 0;
  left: 3rem;
}

.record-edit-menu .control .input {
  text-align: left;
  font-weight: unset;
  padding-top: .25rem;
  padding-left: .5rem;
}

.record-edit-menu .type-select {
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  margin: .125rem 0;
  display: flex;
}

.record-edit-menu .type-select .box {
  will-change: transition;
  font-size: .75rem;
  transition: all .25s;
}

.record-edit-menu .type-select .box span {
  color: #aeaeb2;
  border: .1rem solid #54545899;
  border-radius: 4px;
  padding: .075rem .25rem 0;
}

.record-edit-menu input[type="radio"] {
  display: none;
}

.record-edit-menu input[type="radio"]:checked + .box span {
  color: #2c2c2e;
  background: #57da8edb;
}

.raid-record-row {
  background: #78788020;
  margin-bottom: .375rem;
  display: flex;
}

.raid-record-row .item-wrapper {
  position: relative;
}

.raid-record-row .item-wrapper > .media {
  width: 400px;
  background: none;
  margin-bottom: 0;
}

.raid-record-row .price-wrapper {
  justify-content: flex-end;
  margin-left: auto;
  padding: .25rem;
  position: relative;
}

.raid-record-row .price-wrapper > .x-icon {
  height: 100%;
  margin: 0;
}

.raid-record-row .price-wrapper .price-context {
  min-width: 4rem;
  height: 100%;
  cursor: pointer;
  font-variant-numeric: tabular-nums;
  place-content: center space-between;
  align-items: center;
  margin-right: -.5rem;
  padding: 0 .5rem;
  font-family: Oxanium;
  display: flex;
}

.raid-record-row .price-wrapper .price-context:hover {
  background: #78788052;
  border-radius: 4px;
}

.raid-record-row .price-wrapper .price-context .x-icon {
  margin: 0;
}

.raid-record-row .price-wrapper .price-context .price-text {
  color: #e5e5ea;
  text-align: right;
  margin-bottom: -.25rem;
  padding-left: 1rem;
  font-size: 1.125rem;
  font-weight: 500;
}

.raid-record-row .price-wrapper .price-dropdown {
  height: 100%;
  width: 7rem;
  z-index: 21;
  font-variant-numeric: tabular-nums;
  background: #1e1e1e;
  font-family: Oxanium;
  position: absolute;
  top: 0;
  right: 0;
}

.raid-record-row .price-wrapper .price-dropdown .input {
  padding-top: .25rem;
}

.raid-record-row .dropdown-menu {
  float: right;
  justify-content: flex-end;
  padding: .25rem 0;
  position: relative;
}

.raid-record-row .dropdown-menu > .button {
  opacity: 0;
  height: 100%;
  padding: 0;
}

.raid-record-row .dropdown-menu > .button:hover {
  opacity: 1;
  background: none;
}

.raid-record-row .dropdown-menu .button.show {
  opacity: 1;
}

.raid-record-row .dropdown-menu .menu-context {
  height: 100%;
  background: #333337;
  align-items: center;
  padding: 0 .125rem;
  display: flex;
  position: absolute;
  top: 0;
  right: 1.125rem;
}

.raid-record-row .dropdown-menu .menu-context .button {
  width: 2rem;
  margin: 0 .25rem;
  padding: 0;
}

.raid-record-row .dropdown-menu .menu-context .button > span.icon {
  justify-content: center;
  display: flex;
}

.raid-containter {
  grid-gap: .375rem;
  width: calc(940px + .375rem);
  outline: .25rem solid #78788052;
  grid-template-columns: 680px 260px;
  grid-template-areas: "control summary"
                       "record-main summary"
                       "record-extra summary";
  margin: 2rem auto auto;
  padding: .375rem;
  display: grid;
}

.raid-containter.is-loading > * {
  visibility: hidden;
}

.raid-containter .raid-message {
  z-index: 2;
  visibility: visible;
  color: #fff;
  width: 30rem;
  height: 10rem;
  background: #111;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  margin: 8rem auto auto;
  font-family: Oxanium;
  font-size: 1.25rem;
  font-weight: 500;
  display: flex;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.raid-containter .raid-message .message:before {
  content: "";
  width: 1.75rem;
  height: 1.75rem;
  box-sizing: border-box;
  border: .25rem solid #d1d1d6;
  border-bottom-color: #0000;
  border-radius: 50%;
  margin-top: -.5rem;
  margin-left: -2.25rem;
  animation: rotation 1s linear infinite;
  display: inline-block;
  position: absolute;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.raid-containter .raid-message.is-not-found {
  height: 14rem;
  flex-direction: column;
}

.raid-containter .raid-message.is-not-found i.icon {
  height: 2.5rem;
  width: 2.5rem;
}

.raid-containter .raid-message.is-not-found i.icon > svg {
  fill: #d96057;
}

.raid-containter .raid-message.is-not-found .error-message {
  margin-top: 1.25rem;
  margin-bottom: 1.75rem;
}

.raid-containter .raid-message.is-not-found .button {
  font-size: 1rem;
}

.raid-summary {
  background: #111;
  grid-area: summary;
}

.raid-control {
  background: #111;
  flex-direction: row-reverse;
  grid-area: control;
  padding: .25rem .5rem;
  display: flex;
}

.raid-control .button {
  background: none;
  border: none;
  margin-left: .25rem;
}

.raid-control .raid-title {
  flex-grow: 1;
  order: 100;
  align-content: center;
  align-items: center;
  display: flex;
}

.raid-control .raid-title .title {
  color: #e5e5ea;
  max-width: 16rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: -.125rem;
  margin-left: .25rem;
  font-family: Oxanium;
  font-weight: 500;
  overflow: hidden;
}

.raid-control .raid-title span.icon.pad {
  padding: 1px 6px;
}

.raid-control .raid-title i.icon > svg {
  fill: #d1d1d6;
}

.raid-control .raid-title .button {
  margin-left: 0;
}

.button {
  -webkit-user-select: none;
  user-select: none;
  height: 2rem;
  cursor: pointer;
  border: none;
  border-radius: 4px;
}

.button.is-text {
  height: unset;
  color: #ebebf599;
  background: #ebebf51f;
  border: .1rem solid #54545899;
  padding: calc(.5rem - 1px) 2rem;
  font-weight: 500;
}

.button.is-text:hover {
  background: #ebebf53d;
}

.button.is-text.fix {
  padding-bottom: calc(.375rem - 1px);
}

.button.is-text.is-primary {
  color: #1c1c1ec2;
  background: #aeaeb2;
}

.button.is-text.is-primary:hover {
  background: #c7c7cc;
}

.button.no-bg {
  background: none;
  border: none;
}

.button:hover {
  background: #78788052;
}

.button i.icon {
  width: 1.125rem;
  height: 1.125rem;
}

.button i.icon > svg {
  fill: #aeaeb2;
  width: inherit;
  height: inherit;
  display: block;
}

.control {
  height: 100%;
  display: flex;
}

.control.has-icon-right .input {
  padding-right: 2.5rem;
}

.control .input, .control .select {
  width: 100%;
  text-align: right;
  color: #e5e5ea;
  background: #7878805c;
  border: 1px solid #54545899;
  border-radius: 4px;
  outline: none;
  margin: .25rem;
  font-weight: 500;
}

.control .input:focus, .control .select:focus {
  border: .1rem solid #0a84ff;
}

.control .select {
  color: #d1d1d6;
  margin-right: 1rem;
  font-weight: 400;
}

.control .select option:disabled {
  color: #c7c7cc;
}

.control .checkbox {
  display: none;
}

.control .checkbox:checked + label {
  background: #7878805c;
  border-color: #d1d1d6;
}

.control .checkbox:checked + label:after {
  background: #f2f2f7;
  margin-left: 2rem;
}

.control .checkbox + label {
  width: 3.5rem;
  background: none;
  border: .125rem solid #ebebf54d;
  border-radius: 4px;
  margin-right: .25rem;
  position: relative;
}

.control .checkbox + label:after {
  content: " ";
  height: 1.25rem;
  width: 1.25rem;
  background: #aeaeb2;
  border-radius: 4px;
  margin-top: .25rem;
  margin-left: .25rem;
  transition: all .25s ease-out;
  display: block;
}

.control span.icon {
  -webkit-user-select: none;
  user-select: none;
  height: 100%;
  cursor: pointer;
  z-index: 25;
  border-radius: 4px;
  align-items: center;
  display: inline-flex;
  position: absolute;
}

.control span.icon.is-right {
  justify-content: flex-end;
  right: .75rem;
}

i.icon {
  width: 1.125rem;
  height: 1.125rem;
}

i.icon > svg {
  fill: #aeaeb2;
  width: inherit;
  height: inherit;
  display: block;
}

i.icon.is-hover {
  padding: .25rem;
}

i.icon.is-hover:hover {
  background: #78788052;
  border-radius: 4px;
}

i.is-green > svg {
  fill: #57da8edb;
}

.x-icon {
  height: 1.5rem;
  width: 1.5rem;
  margin: auto;
  display: block;
  background-size: contain !important;
}

.x-icon-disenchant {
  background: url("https://cdn-z3.c70.ca/icons/inv_enchant_disenchant.webp") center no-repeat;
}

.x-icon-extra {
  background: url("https://cdn-z3.c70.ca/icons/wow_token01.webp") center no-repeat;
}

.x-icon-gold {
  background: url("https://cdn-z3.c70.ca/icons/inv_misc_coin_01.webp") center no-repeat;
}

.x-icon-gold2 {
  background: url("https://cdn-z3.c70.ca/icons/inv_misc_coin_02.webp") center no-repeat;
}

.x-icon-gold3 {
  background: url("https://cdn-z3.c70.ca/icons/inv_misc_coin_17.webp") center no-repeat;
}

.modal-background {
  z-index: 10;
  background: #000000a8;
  position: absolute;
  inset: 0;
}

.modal {
  z-index: 100;
  width: 600px;
  height: 400px;
  background: #111;
  border-radius: 4px;
  flex-direction: column;
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal .modal-head {
  background-color: #1c1c1e;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
  padding: .875rem 1.5rem;
  display: flex;
  position: relative;
}

.modal .modal-head .title {
  color: #ebebf599;
  flex-grow: 1;
  margin-bottom: -.25rem;
  font-family: Oxanium;
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 1.5;
}

.modal .modal-head .title:empty:before {
  content: " ";
  padding-left: 1rem;
}

.modal .modal-head span.icon {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-right: -.5rem;
  display: flex;
}

.modal .modal-head span.icon:hover {
  background: #78788052;
  border-radius: 4px;
}

.modal .modal-body {
  flex-direction: column;
  flex-grow: 1;
  padding: .875rem 1.5rem;
  display: flex;
}

.modal .modal-body .dynamic-size {
  flex-grow: 1;
}

.modal .modal-foot {
  background-color: #1c1c1e;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: flex-end;
  padding: .875rem 1.5rem;
  display: flex;
  position: relative;
}

.modal .modal-foot .foot-buttons .button {
  margin-left: 1rem;
}

.textarea {
  resize: none;
  color: #ebebf599;
  background: #1c1c1e;
  border: .1rem solid #54545899;
  border-radius: 4px;
  outline: none;
  margin-bottom: .75rem;
  padding: calc(.5rem - 1px) calc(.75rem - 1px);
}

.textarea:focus-visible {
  border-color: #0a84ff;
  padding: calc(.625rem - 1px) calc(.875rem - 1px);
}

.textarea::placeholder {
  color: #ebebf54d;
}

.textarea::-webkit-scrollbar {
  width: 8px;
}

.textarea::-webkit-scrollbar-thumb {
  background-color: #ebebf54d;
}

.textarea::-webkit-scrollbar-track {
  background: #2c2c2e;
}

.textarea.is-fixwidth {
  font-family: PragmataPro Liga, ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
  font-size: .875rem;
}

.field:not(:last-child) {
  margin-bottom: .75rem;
}

.switch[type="checkbox"] {
  -webkit-user-select: none;
  user-select: none;
  opacity: 0;
  outline: 0;
  display: inline-block;
  position: absolute;
}

.switch[type="checkbox"] + label {
  color: #ebebf599;
  cursor: pointer;
  padding-top: .125rem;
  padding-left: 3rem;
  font-size: 1rem;
  position: relative;
}

.switch[type="checkbox"] + label:before {
  width: 2.25rem;
  height: 1.125rem;
  content: " ";
  background: none;
  border: .1rem solid #54545899;
  border-radius: 4px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.switch[type="checkbox"] + label:after {
  width: .825rem;
  height: .825rem;
  content: " ";
  background: #54545899;
  border-radius: 4px;
  transition: all .25s ease-out;
  display: block;
  position: absolute;
  top: .25rem;
  left: .25rem;
  transform: translate3d(0, 0, 0);
}

.switch[type="checkbox"]:checked + label:before {
  border-color: #aeaeb2;
}

.switch[type="checkbox"]:checked + label:after {
  background: #aeaeb2;
  left: 1.375rem;
}

.raid-card-container {
  grid-gap: .375rem;
  grid-template-rows: repeat(auto-fill, 3rem);
  grid-template-columns: 1fr 1fr;
  padding: .375rem;
  display: grid;
}

.raid-card-container .card {
  background: #2c2c2e;
  align-items: center;
  padding: 0 .875rem;
  display: flex;
}

.raid-card-container .card .title {
  font-variant-numeric: tabular-nums;
  text-align: right;
  color: #d1d1d6;
  flex-grow: 1;
  margin: .5rem 0 .375rem .5rem;
  font-family: Oxanium;
  font-size: 1.25rem;
}

.raid-card-container .card .title.is-gold {
  color: #e6cc80;
}

.raid-card-container .card i.icon {
  width: 1.375rem;
  height: 1.375rem;
}

.raid-card-container .card.is-double {
  flex-direction: column;
  grid-row: 2 span;
  align-items: center;
}

.raid-card-container .card.is-double i.icon {
  width: 2.25rem;
  height: 2.25rem;
}

.raid-card-container .card.is-double span.icon {
  margin-top: 1rem;
}

.raid-card-container .card.is-double .title {
  color: #e5e5ea;
  margin: .5rem;
}

.raid-card-container .card.gold-card {
  background: none;
  grid-area: 2 span / 2 span;
  justify-content: space-between;
  padding: 1rem 1.625rem;
  display: flex;
}

.raid-card-container .card.gold-card .group-right {
  flex-direction: column;
  align-items: flex-end;
  display: flex;
}

.raid-card-container .card.gold-card .title {
  color: #f5f5f7;
  margin: 0 0 -.5rem;
  font-size: 2rem;
}

.raid-card-container .card.gold-card .subtitle {
  text-transform: uppercase;
  font-family: Oxanium;
}

.raid-card-container .card.gold-card .group-left {
  align-items: center;
  display: flex;
}

.raid-card-container .card.gold-card .group-left .x-icon {
  width: 2.5rem;
  height: 2.5rem;
}

.raid-card-container .card.is-record {
  height: 2.5rem;
  grid-column: 2 span;
}

.raid-card-container .card.is-record .title {
  flex-grow: 0;
  order: 3;
}

.raid-card-container .card.is-record .subtitle {
  height: 100%;
  flex-grow: 1;
  padding-top: .5rem;
  padding-left: .75rem;
  font-family: Oxanium;
}

.raid-card-container .card.is-record .subtitle[data-desc]:before {
  content: attr(data-desc);
  color: #ebebf54d;
  margin-top: 1.125rem;
  font-size: .75rem;
  position: absolute;
}

.raid-card-container .card.copiable {
  cursor: pointer;
}

.raid-card-container .card.is-copied {
  position: relative;
}

.raid-card-container .card.is-copied:after {
  content: "Copied";
  z-index: 20;
  color: #57da8edb;
  background: #1c1c1e;
  border-radius: 4px;
  padding: .5rem .5rem .5rem 2rem;
  font-family: Oxanium;
  font-size: .875rem;
  font-weight: 500;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.raid-card-container .card.is-copied:before {
  content: " ";
  width: 1rem;
  height: 1rem;
  z-index: 21;
  background-color: #57da8edb;
  margin-left: -1.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z'/%3E%3C/svg%3E") no-repeat;
}

.raid-config-bonus {
  margin-top: .375rem;
  font-family: Oxanium;
}

.raid-config-bonus .config-header {
  align-items: flex-end;
  display: flex;
}

.raid-config-bonus .config-header .title {
  color: #ebebf599;
  flex-grow: 1;
  margin-left: .5rem;
}

.raid-config-bonus .config-header .button {
  height: 1.5rem;
  background: none;
  border: none;
  margin-left: .5rem;
}

.raid-config-bonus .config-header .button:hover {
  background: #78788052;
}

.raid-config-bonus .config-containter {
  height: 200px;
  background: #1c1c1e;
  border-radius: 4px;
  margin-top: .375rem;
  overflow-y: auto;
}

.raid-config-bonus .config-containter::-webkit-scrollbar {
  width: 8px;
}

.raid-config-bonus .config-containter::-webkit-scrollbar-thumb {
  background-color: #ebebf54d;
}

.raid-config-bonus .config-containter::-webkit-scrollbar-track {
  background: #2c2c2e;
}

.raid-config-bonus .raid-config-row {
  height: 1.875rem;
}

.raid-config-row {
  background: #78788052;
  border-radius: 4px;
  align-items: center;
  margin-bottom: .375rem;
  padding: .375rem .75rem;
  font-family: Oxanium;
  display: flex;
  position: relative;
}

.raid-config-row span.icon {
  margin-right: 1rem;
}

.raid-config-row span.icon i.icon {
  width: 1.5rem;
  height: 1.5rem;
}

.raid-config-row p.title {
  min-width: 10rem;
  margin-bottom: -.125rem;
  font-weight: 400;
}

.raid-config-row .control {
  flex-grow: 1;
  justify-content: flex-end;
}

.raid-config-row .control.with-radio {
  position: relative;
}

.raid-config-row .control.with-radio .input {
  width: 3.5rem;
  text-align: center;
  margin: 0 .75rem 0 0;
  padding-top: .25rem;
  font-weight: 400;
}

.raid-config-row.simple-input {
  height: 2rem;
}

.raid-config-row.simple-input .input {
  width: 4rem;
  text-align: center;
  margin: 0 .75rem 0 0;
  padding-top: .25rem;
}

.raid-config-row.simple-input .select {
  text-align: center;
  margin: 0 .125rem 0 0;
  padding-top: .25rem;
  padding-left: .75rem;
}

.raid-config-row .select-overlayer {
  z-index: 10;
  background: #3b3b3e;
  display: flex;
  position: absolute;
}

.raid-config-row .select-overlayer .control {
  height: unset;
  margin: 0 1rem;
}

.raid-config-row .select-overlayer .control input.input {
  text-align: left;
  width: 12rem;
  margin: 0;
  padding-top: .25rem;
  padding-left: .5rem;
  font-weight: 400;
}

.raid-config-row .select-overlayer .control.has-icon-right .input {
  padding-right: 2rem;
}

.raid-config-row .select-overlayer .control.has-icon-right span.icon {
  right: .25rem;
}

.raid-config-row .select-overlayer .control.has-icon-right i.icon {
  width: 1.25rem;
  height: 1.25rem;
}

.raid-config-row .select-overlayer .control.has-icon-right i.icon.is-hover {
  padding: .125rem;
}

.raid-config-row .select-overlayer .icon-selector {
  display: flex;
}

.raid-config-row .select-overlayer .icon-selector input[type="radio"] {
  display: none;
}

.raid-config-row .select-overlayer .icon-selector input[type="radio"]:checked + .box-icon {
  background: #7878805c;
  border-color: #f2f2f7;
}

.raid-config-row .select-overlayer .icon-selector input[type="radio"]:checked + .box-icon svg {
  fill: #fff;
}

.raid-config-row .select-overlayer .icon-selector .box-icon {
  cursor: pointer;
  border: .1rem solid #ebebf54d;
  border-radius: 4px;
  margin-right: .325rem;
  padding: .325rem;
}

.raid-config-row .control.with-radio .type-select {
  flex-direction: column;
  order: 2;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: -.125rem;
  margin-bottom: -.25rem;
  margin-right: .5rem;
  display: flex;
}

.raid-config-row .control.with-radio .type-select .box {
  will-change: transition;
  font-size: .75rem;
  transition: all .25s;
}

.raid-config-row .control.with-radio .type-select .box span {
  color: #aeaeb2;
  border: .1rem solid #ebebf54d;
  border-radius: 4px;
  padding: .075rem .25rem 0;
}

.raid-config-row .control.with-radio .type-select input[type="radio"] {
  display: none;
}

.raid-config-row .control.with-radio .type-select input[type="radio"]:checked + .box span {
  color: #fff;
  background: #7878805c;
  border-color: #f2f2f7;
}

.home-containter {
  width: calc(940px + .375rem);
  height: 728px;
  grid-gap: .375rem;
  outline: .25rem solid #78788052;
  grid-template-columns: 680px 260px;
  margin: 2rem auto auto;
  padding: .375rem;
  display: grid;
}

.home-main {
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.home-main .record-control {
  font-family: Oxanium;
}

.record-containter {
  flex-direction: column;
  margin-top: .25rem;
  font-family: Oxanium;
  display: flex;
  overflow-y: auto;
}

.record-containter::-webkit-scrollbar {
  width: 8px;
}

.record-containter::-webkit-scrollbar-thumb {
  background-color: #ebebf54d;
}

.record-containter::-webkit-scrollbar-track {
  background: #2c2c2e;
}

.record-containter section.group {
  grid-gap: .375rem;
  flex-grow: 1;
  grid-template-rows: 1.25rem repeat(auto-fill, 5rem);
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 5rem;
  margin: .375rem .5rem;
  display: grid;
}

.record-containter section.group header {
  color: #d1d1d6;
  grid-column: 3 span;
  margin-left: .75rem;
}

.record-containter section.group .card {
  background: #1c1c1e;
  border-radius: 4px;
  display: flex;
}

.record-containter section.group .card .layer {
  cursor: pointer;
  background: #0b0b0b52;
  border-radius: 4px;
  flex-direction: column;
  flex-grow: 1;
  padding: .375rem .5rem;
  display: flex;
  position: relative;
}

.record-containter section.group .card .layer .title {
  color: #c7c7cc;
  max-width: 12.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  background: #1c1c1e99;
  border-radius: 4px;
  margin-bottom: .125rem;
  padding: .125rem .25rem 0;
  overflow: hidden;
}

.record-containter section.group .card .layer .subtitle {
  color: #aeaeb2;
  width: -moz-fit-content;
  width: fit-content;
  background: #2c2c2e99;
  border-radius: 4px;
  padding: .125rem .25rem 0;
}

.record-containter section.group .card .layer .button {
  background: none;
}

.record-containter section.group .card .layer:hover {
  background: none;
  outline: 1px solid #aeaeb2;
}

.record-containter section.group .card .layer:hover .btn-remove {
  height: 1.875rem;
  display: block;
}

.record-containter section.group .card .layer:hover .btn-remove:hover {
  background: #787880cc;
}

.record-containter section.group .card .layer .btn-remove {
  z-index: 1;
  display: none;
  position: absolute;
  top: .125rem;
  right: .125rem;
}

.record-containter section.group .card .layer .btn-remove i.icon > svg {
  fill: #d1d1d6;
}

.record-containter section.group .card.is-template:hover .btn-add {
  opacity: 1;
}

.record-containter section.group .card.is-template:hover .title, .record-containter section.group .card.is-template:hover .subtitle {
  opacity: 0;
}

.record-containter section.group .card.is-template .btn-add {
  opacity: 0;
  z-index: 1;
  height: 2.75rem;
  background: #1c1c1e99;
  position: absolute;
  top: 1rem;
  left: calc(50% - 22px);
}

.record-containter section.group .card.is-template .btn-add i.icon {
  width: 2rem;
  height: 2rem;
}

.record-containter section.group .card.is-template .btn-add i.icon svg {
  fill: #c7c7cc;
}

.record-containter section.group .card.is-tbc-bt {
  background: url("https://cdn-z3.c70.ca/slash/tbc_bt.webp") -20px -15px;
}

.record-containter section.group .card.is-tbc-swp {
  background: url("https://cdn-z3.c70.ca/slash/tbc_swp.webp") -20px -5px;
}

.record-containter section.group .card.is-wotlk-naxx {
  background: url("https://cdn-z3.c70.ca/slash/wotlk_naxx.webp") -40px -100px;
}

.record-containter section.group .card.is-wotlk-eoe {
  background: url("https://cdn-z3.c70.ca/slash/wotlk_eoe.webp") -60px -80px;
}

.record-containter section.group .card.is-wotlk-ob {
  background: url("https://cdn-z3.c70.ca/slash/wotlk_ob.webp") -40px -150px;
}

.record-containter section.group .card.is-wotlk-toc {
  background: url("https://cdn-z3.c70.ca/slash/wotlk_toc.webp") -15px -75px / 250px;
}

.record-containter section.group .card.is-wotlk-icc {
  background: url("https://cdn-z3.c70.ca/slash/wotlk_icc.webp") -25px 0 / 260px;
}

.record-containter section.group .card.is-wotlk-uld {
  background: url("https://cdn-z3.c70.ca/slash/wotlk_uld.webp") -15px -10px;
}

.record-containter section.group .card.is-wotlk-rs {
  background: url("https://cdn-z3.c70.ca/slash/wotlk_rs.webp") -10px -80px;
}

.record-containter section.group .card.is-wotlk-ony {
  background: url("https://cdn-z3.c70.ca/slash/wotlk_ony.webp") -10px 0 / 260px;
}

.record-containter section.group .card.is-wotlk-vault {
  background: url("https://cdn-z3.c70.ca/slash/wotlk_vault.webp") 0 -30px / 250px;
}

.home-info-screen {
  flex-direction: column;
  align-items: center;
  margin-top: 6rem;
  font-family: Oxanium;
  display: flex;
}

.home-info-screen.loading .title {
  margin-top: 1.5rem;
  font-size: 1.5rem;
  font-weight: 500;
}

.home-info-screen.ops i.icon {
  width: 4rem;
  height: 4rem;
}

.home-info-screen.ops span.icon {
  margin-bottom: 1rem;
}

.home-info-screen.ops .title {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.home-info-screen .is-loading {
  width: 6rem;
  height: 6rem;
  box-sizing: border-box;
  border: .5rem solid #d1d1d6;
  border-bottom-color: #0000;
  border-radius: 50%;
  display: inline-block;
}

.raid-export-loot {
  width: 669px;
  max-height: 400px;
  flex-direction: column;
  margin-left: auto;
  display: flex;
  position: relative;
  overflow-y: scroll;
}

.raid-export-loot::-webkit-scrollbar {
  display: none;
}

.raid-export-loot .loot-control-bar {
  z-index: 10;
  flex-direction: row-reverse;
  margin-top: .5rem;
  margin-right: .125rem;
  display: flex;
  position: absolute;
  right: 0;
}

.raid-export-loot .loot-control-bar .x-icon {
  cursor: pointer;
  filter: grayscale();
  margin-right: .5rem;
}

.raid-export-loot .loot-control-bar .x-icon:hover {
  filter: grayscale(40%);
}

.raid-export-loot .loot-control-bar .x-icon.is-select {
  filter: none;
}

.raid-export-loot .loot-body {
  background: #111;
}

.raid-export-loot .loot-body header {
  height: 150px;
}

.raid-export-loot .loot-body header .layer {
  width: 100%;
  height: 100%;
  background: #0b0b0b52;
  justify-content: space-between;
  display: flex;
}

.raid-export-loot .loot-body header .raid-title {
  flex-direction: column;
  padding-top: 1rem;
  padding-left: .75rem;
  display: flex;
}

.raid-export-loot .loot-body header .raid-title .title {
  color: #c7c7cc;
  width: -moz-fit-content;
  width: fit-content;
  background: #1c1c1e99;
  border-radius: 4px;
  margin-bottom: .125rem;
  padding: .25rem .375rem 0;
  font-family: Oxanium;
  font-size: 1.5rem;
}

.raid-export-loot .loot-body header .raid-title .subtitle {
  width: -moz-fit-content;
  width: fit-content;
  color: #aeaeb2;
  background: #2c2c2e99;
  border-radius: 4px;
  padding: .25rem .375rem;
  font-size: 1.25rem;
}

.raid-export-loot .loot-body header .raid-info {
  flex-direction: column-reverse;
  align-items: flex-end;
  padding-bottom: 1rem;
  padding-right: .75rem;
  display: flex;
}

.raid-export-loot .loot-body header .raid-info .subtitle {
  width: -moz-fit-content;
  width: fit-content;
  color: #aeaeb2;
  background: #2c2c2e99;
  border-radius: 4px;
  margin-top: .125rem;
  padding: .125rem .25rem;
  font-size: 1rem;
}

.raid-export-loot .loot-body header .raid-info .subtitle:empty {
  display: none;
}

.raid-export-loot .loot-body main {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.raid-export-loot .loot-body .raid-record-row {
  height: 41.5px;
}

.raid-export-loot .loot-body .raid-record-row .price-wrapper {
  margin-right: 1rem;
}

.raid-export-loot .loot-body .raid-record-row .item-wrapper {
  margin-left: .625rem;
}

.raid-export-loot .loot-body .raid-record-row.x-disenchant > .price-wrapper .price-context {
  justify-content: flex-end;
}

.raid-export-loot .loot-body .raid-record-row.x-disenchant > .price-wrapper .price-text {
  display: none;
}

.raid-export-loot .loot-body .raid-record-row.x-disenchant > .price-wrapper .x-icon-gold {
  background: url("https://cdn-z3.c70.ca/icons/inv_enchant_disenchant.webp") center no-repeat;
}

.raid-export-loot .loot-body .raid-record-row.x-extra.x-credit .subtitle {
  color: #57da8edb;
  font-variant: petite-caps;
}

.raid-export-loot .loot-body .raid-record-row.x-extra .subtitle {
  color: #d96057;
  font-variant: petite-caps;
}

.raid-export-loot .loot-body .raid-record-row.x-extra .title > a:empty:after {
  content: "N/A";
}

.raid-export-loot .loot-body .raid-record-row .title {
  display: inline-block;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.Toastify__toast-body {
  white-space: pre-line;
}

html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

/*# sourceMappingURL=index.5ab6ff3f.css.map */
